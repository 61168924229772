<template>
  <div class="HomePage">
    <div>
      <img
        src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%A4%B4%E5%9B%BE.jpg"
        style="width: 100%; height: auto; display: block"
      />
    </div>
  </div>
</template>
<script>
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      image: [],
    };
  },
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  created() {},
});
</script>
<style scoped>
.HomePage {
  width: 100%;
  height: 100%;
}
/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 400px;
  line-height: 400px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>