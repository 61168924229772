<template>
  <div>
    <h1>关于我们</h1>
  </div>
</template>
<script>
export default {
  name: "fourthItem",
  data() {
    return {};
  },
};
</script>