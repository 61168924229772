<template>
  <div>
    <h1>第五项组件</h1>
  </div>
</template>
<script>
export default {
  name: "fifthItem",
  data() {
    return {};
  },
};
</script>