<template>
  <div class="homeBox">
    <a-layout>
      <a-anchor>
        <a-layout-header style="height: 150px; width: 100%; overflow: hidden">
          <div>
            <img
              src="@/assets/logo.png"
              style="width: auto; height: 80%; display: block; margin: 10px"
              alt
            />
          </div>
          <a-menu v-model:selectedKeys="current" mode="horizontal">
            <a-menu-item key="HomePage" @click="HomePageBtn">首页</a-menu-item>
            <a-menu-item key="secondItem" @click="secondItemBtn">愿景</a-menu-item>
            <a-menu-item key="thirdItem" @click="thirdItemBtn">产品</a-menu-item>
            <a-menu-item key="fourthItem" @click="fourthItemBtn">关于我们</a-menu-item>
          </a-menu>
        </a-layout-header>
      </a-anchor>
      <a-layout-content>
        <div id="HomePage" style="width: 100%;">
          <img
            src="https://tech001.oss-cn-hangzhou.aliyuncs.com/opk/%E5%A4%B4%E5%9B%BE.jpg"
            style="width: 100%; height: auto; display: block"
          />
        </div>
        <div style="
            width: 100%;
          " id="secondItem">
          <img
            src="https://tech001.oss-cn-hangzhou.aliyuncs.com/opk/%E6%96%B0%E5%85%AC%E5%8F%B8%E6%84%BF%E6%99%AF.jpg"
            alt
            style="width:100%;height:auto; display: block"
          />
        </div>
        <div
          style="
            width: 100$;
            height:100px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          id="thirdItem"
        >
          <div>
            <img
              src="https://tech001.oss-cn-hangzhou.aliyuncs.com/opk/%E6%96%B0%E4%BA%A7%E5%93%81.jpg"
              alt
              style="width:100%;height:auto; display: block"
            />
          </div>
        </div>
        <div style="margin-bottom:40px;">
          <p style="font-size:18px;">分期支付产品</p>
          <div
            style="
            padding:0 100px;
            display: flex;
            justify-content: center;
            align-items: center;"
          >
            <img src="@/assets/25@3x.png" alt style="width:20%;height:auto;" />
            <img src="@/assets/26@3x.png" alt style="width:20%;height:auto;margin:0 40px;" />
            <img src="@/assets/27@3x.png" alt style="width:20%;height:auto;" />
          </div>
          <p style="font-size:18px;margin-top:40px;">全额支付产品</p>
          <div
            style="
            padding:0 100px;
            display: flex;
            justify-content: center;
            align-items: center;"
          >
            <img src="@/assets/30@3x.png" alt style="width:20%;height:auto;" />
            <img src="@/assets/32@3x.png" alt style="width:20%;height:auto;margin:0 40px;" />
            <img src="@/assets/33@3x.png" alt style="width:20%;height:auto;" />
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer style="padding: 20px 200px" id="fourthItem">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="text-align: left">
            <p>
              <span style="margin-right: 15px">
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E9%82%AE%E7%AE%B1%402x.png"
                  style="width: 15px; height: 15px"
                  alt
                />
              </span>
              邮箱：Service@OPK-FinTech.com
            </p>
            <p style="margin: 20px 0">
              <span style="margin-right: 15px">
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%9C%B0%E5%9D%80%402x.png"
                  style="width: 15px; height: 20px"
                  alt
                />
              </span>
              地址：广州市白云区永平街集贤路280号
            </p>
            <p>
              <span style="margin-right: 15px">
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%B7%A5%E4%BD%9C%E6%97%B6%E9%97%B4%402x.png"
                  style="width: 15px; height: 15px"
                  alt
                />
              </span>
              工作时间：周一至周五9:00-18:00
            </p>
          </div>
        </div>
        <div style="font-weight: bold; margin-left: 25px">
          <a href="http://beian.miit.gov.cn/" target="_blank" style="color: white">粤ICP备2023104886号</a>
          © 欧佩克数字科技 OPK-FinTech 
        </div>
      </a-layout-footer>
    </a-layout>
    <a-back-top />
    <strong style="color: rgba(64, 64, 64, 0.6)"></strong>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      current: ["HomePage"]
    };
  },
  created() {},
  methods: {
    HomePageBtn() {
      document.getElementById("HomePage").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center"
      });
    },
    secondItemBtn() {
      document.getElementById("secondItem").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "start"
      });
    },
    thirdItemBtn() {
      document.getElementById("thirdItem").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "start"
      });
    },
    fourthItemBtn() {
      document.getElementById("fourthItem").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "start"
      });
    }
  }
};
</script>

<style lang="less">
.ant-layout-header {
  height: 100px !important;
  background: white !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom: 1px #ccc solid;
  div {
    height: 70px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
.ant-layout-content {
  flex: auto;
  min-height: 0;
  text-align: center;
  background: white;
  padding: 0px 80px !important;
}
.ant-layout-footer {
  text-align: center;
  padding: 24px 50px;
  color: white !important;
  font-size: 14px;
  background: #015089 !important;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
  justify-content: center !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-top: -1px;
  margin-bottom: 0;
  // padding: 0 30px !important;
  margin: 0 35px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  background: #edf4fe;
  color: #3cb4f1;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  color: #3cb4f1;
  border-bottom: 2px solid #3cb4f1 !important;
  text-align: center;
  width: 100%;
  left: 0;
}
.ant-menu-horizontal > .ant-menu-item::after{
  width: 100%;
  left: 0;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  font-weight: bold !important;
  color: #015089 !important;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #015089 !important;
  font-weight: bold !important;
}
.van-tabs__nav--line {
  padding: 0 20% !important;
  padding-bottom: 15px !important;
  margin-bottom: 10px;
}
.van-tabs--line .van-tabs__wrap {
  margin-bottom: 5px !important;
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  border: 2px solid #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #015089;
  color: #fff;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px;
}
</style>
