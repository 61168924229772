import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomePage from '../views/content/HomePage.vue'
import secondItem from '../views/content/secondItem.vue'
import thirdItem from '../views/content/thirdItem.vue'
import fourthItem from '../views/content/fourthItem.vue'
import fifthItem from '../views/content/fifthItem.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/HomePage',
        name: 'HomePage',
        component: HomePage,
      },
      {
        path: '/secondItem',
        name: 'secondItem',
        component: secondItem,
      },
      {
        path: '/thirdItem',
        name: 'thirdItem',
        component: thirdItem,
      },
      {
        path: '/fourthItem',
        name: 'fourthItem',
        component: fourthItem,
      },
      {
        path: '/fifthItem',
        name: 'fifthItem',
        component: fifthItem,
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
