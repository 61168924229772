<template>
  <router-view />
</template>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
  background-image: radial-gradient(#ccc 40%, transparent 0);
  background-size: 5px 5px;
}
</style>
