<template>
  <div>
    <h1>产品</h1>
  </div>
</template>
<script>
export default {
  name: "thirdItem",
  data() {
    return {};
  },
};
</script>